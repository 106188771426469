// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-js": () => import("/opt/build/repo/web/src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-units-js": () => import("/opt/build/repo/web/src/pages/business-units.js" /* webpackChunkName: "component---src-pages-business-units-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/web/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/web/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-references-js": () => import("/opt/build/repo/web/src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-team-js": () => import("/opt/build/repo/web/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

