module.exports = [{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"IGNIS - Fire, Design, Consulting","short_name":"IGNIS","start_url":"/","background_color":"#000","theme_color":"#fff","display":"minimal-ui","icon":"src/favicon-big.png"},
    },{
      plugin: require('/opt/build/repo/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
